var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("h3", [
      _vm._v(
        "An unexpected error has occurred - refresh the page or if you wait we will try again shortly"
      ),
    ]),
    _vm._v(" "),
    _c("div", [
      _c("a", { attrs: { href: "#" }, on: { click: _vm.refresh } }, [
        _vm._v("Refresh to try again"),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "error" }, [
      _c("pre", [_vm._v(_vm._s(_vm.message))]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }