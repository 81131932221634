var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c(
        "v-main",
        [
          _c(
            "v-container",
            { attrs: { fluid: "", "fill-height": "" } },
            [
              _c(
                "v-layout",
                { attrs: { "align-center": "", "justify-center": "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", sm10: "", md8: "" } },
                    [
                      _c(
                        "v-card",
                        { staticClass: "elevation-6" },
                        [
                          _c(
                            "v-toolbar",
                            { attrs: { color: "primary" } },
                            [
                              _c("v-toolbar-title", [
                                _vm._v("No licence found for "),
                                _c(
                                  "span",
                                  {
                                    attrs: {
                                      "data-test-name": "no-licence-with-user",
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.user))]
                                ),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-card-title",
                            {
                              attrs: { "primary-title": "", color: "primary" },
                            },
                            [
                              _c("h3", { staticClass: "headline mb-0" }, [
                                _vm._v(
                                  "You don't appear to have access to this product."
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c("v-divider"),
                          _vm._v(" "),
                          _c(
                            "v-card-text",
                            [
                              _c("p", [
                                _vm._v(
                                  "\n                                If you would like to obtain a licence or know more please call us on\n                                "
                                ),
                                _c(
                                  "a",
                                  { attrs: { href: "tel:+61 (0)7 3368 4064" } },
                                  [_vm._v("+61 (0)7 3368 4064")]
                                ),
                                _vm._v(".\n                            "),
                              ]),
                              _vm._v(" "),
                              _c("v-spacer"),
                              _vm._v(" "),
                              _c("p", [
                                _vm._v(
                                  "\n                                You may need to try again using a different Global-Roam account.\n                            "
                                ),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-card-actions",
                            [
                              _c("v-spacer"),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary" },
                                  on: { click: _vm.logout },
                                },
                                [
                                  _vm._v(
                                    "\n                                Login with another account\n                            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }