







































import Vue from 'vue';
import Component from 'vue-class-component';
import GlobalRoamAuthentication from '../../authentication';
import { Prop } from 'vue-property-decorator';

@Component
export default class AccessDeniedPage extends Vue {
    @Prop({ required: true })
    authentication!: GlobalRoamAuthentication;

    public logout() {
        this.authentication.logout();
    }

    get user() {
      return this.authentication.user.profile.name ?? 'Unknown';
    }
}
