












import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class ErrorPage extends Vue {
  @Prop({required: true})
  message!: string;

  refresh() {
    window.location.reload();
  }

  created() {
    setTimeout(() => this.refresh(), 10_000)
  }
}
