

































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class GrAuthenticationProfile extends Vue {
    @Prop({ default: 'primary' })
    color!: string;

    @Prop({ default: '' })
    iconColor!: string;

    get userInitial() {
        return (this.$authentication.user.profile.name ? this.$authentication.user.profile.name[0] : '?').toUpperCase();
    }

    get user() {
        return this.$authentication.user.profile.name ?? '---### Unknown user name ###---';
    }

    async logout() {
        await this.$authentication.logout();
    }
}
