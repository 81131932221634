var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: {
        attach: "",
        left: "",
        "offset-y": "",
        "data-test-name": "gr-authentication-profile",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function (ref) {
            var onMenu = ref.on
            return [
              _c(
                "v-tooltip",
                {
                  attrs: {
                    "nudge-bottom": "40",
                    "nudge-left": "40",
                    color: _vm.color,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function (ref) {
                          var onTooltip = ref.on
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                {
                                  attrs: {
                                    icon: "",
                                    color: _vm.iconColor,
                                    "data-test-name": "gr-auth-profile-btn",
                                  },
                                },
                                Object.assign({}, onMenu, onTooltip)
                              ),
                              [
                                _c("v-icon", { attrs: { large: "" } }, [
                                  _vm._v("mdi-account-circle"),
                                ]),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                },
                [_vm._v("\n            Account\n        ")]
              ),
            ]
          },
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "v-card",
        { attrs: { "data-test-name": "gr-auth-profile-menu" } },
        [
          _c(
            "v-toolbar",
            { attrs: { dense: "", color: _vm.color, elevation: "1" } },
            [
              _c(
                "v-icon",
                { staticClass: "mr-4", attrs: { large: "", color: "white" } },
                [_vm._v("mdi-card-account-details")]
              ),
              _vm._v(" "),
              _c("v-toolbar-title", { staticClass: "white--text" }, [
                _vm._v("My Global-Roam Account"),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-list",
            [
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-avatar",
                    { attrs: { color: _vm.color, left: "" } },
                    [_vm._v(_vm._s(_vm.userInitial))]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item-content",
                    [
                      _c(
                        "v-list-item-title",
                        { attrs: { "data-test-name": "gr-auth-profile-user" } },
                        [_vm._v(_vm._s(_vm.user))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { outlined: "", block: "", color: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.logout()
                    },
                  },
                },
                [
                  _c("v-icon", { staticClass: "mr-2" }, [
                    _vm._v("mdi-logout-variant"),
                  ]),
                  _vm._v("Sign\n                out"),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }